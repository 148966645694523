// Breakpoints
$extra-small: 500px;
$small: 767px;
$medium: 1024px;

// used for text
$off-white: rgb(232, 230, 227);
// used for links and link-like things
$link-color: rgb(49, 200, 255);

body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    background-color: rgb(24, 26, 27);
    color: $off-white;
}

header.hero {
    margin: 0;
    // Fallback: blue background
    background-color: rgb(0, 26, 91);
    background: linear-gradient(rgba(0, 26, 91, 0.55) 0%, rgb(0, 26, 91) 100%),
        url("assets/march.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1;
    box-sizing: border-box;

    // Left-align everything on larger screens,
    // center on smaller screens
    @media(min-width: $medium) {
        align-items: left;
        padding: 100px;
        * {
            max-width: 600px;
        }
    }
    @media(max-width: $medium) {
        align-items: center;
        text-align: center;
        padding: 70px;
    }
    @media(max-width: $extra-small) {
        padding: 20px;
    }
}

h1,
h2 {
    text-transform: uppercase;
    margin: 10px;
    line-height: 1;
}
h1 {
    font-weight: 900;
    font-size: 7rem; 
    @media(max-width: $medium) {
        font-size: 65px;
    }

    @media(max-width: $small) {
        font-size: 50px;
    }
}

h2 {
    font-size: 2rem;
    @media(max-width: $small) {
        font-size: 1.4rem;
    }
}

p {
    line-height: 1.5em;
    font-size: 26px;
    @media(max-width: $small) {
        font-size: 20px;
    }
}

a {
    color: $link-color;
    text-decoration: none;
    cursor: pointer;
}
a.dotted {
    color: $off-white;
    border-bottom: 1px dotted $off-white;
}

li {
    font-size: 23px;
    p, summary {
        font-size: 21px;
        margin: 0.1em 0;
    }
    padding: 0.8em 0.1em;   
}


main {
    width: 100%;
    box-sizing: border-box;
    section {
        max-width: 130ch;
        margin: auto;
    }
    padding: 1.5rem;
}
section {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h1, h2 {
        margin: 1em 0 0;
    }

    > p {
        margin: 15px 8px;
    }

    section {
        margin: 15px;
    }
}

.error {
    color: red;

    // We add this fade in animation
    // so that if the same error happens again, it's clear
    // that attempts are still being made.
    // This way we avoid "nothing" happening visually when the user
    // presses the "retry" link.
    animation: fadein 1s;
    @keyframes fadein {
        0% { opacity: 0; }
        100% { opacity: 100; }
    }
}

a.calendar-link {
    // Less important link, so we don't really need to make it link colored
    color: $off-white;
    
    // Aesthetic choices to make it stand out (because the contents, i.e. the date & time, are important)
    text-decoration: underline;
}

summary {
    color: $link-color;
}
details > summary::after {
    content: "Click to show details"
}
details[open] > summary::after {
    content: "Click to hide details"
}
details {
    p {
        margin: 0px 15px;
    }
}

iframe {
    filter: invert(80) hue-rotate(140deg) brightness(1.5);
    width: 100%;
}
body {
  box-sizing: border-box;
  color: #e8e6e3;
  background-color: #181a1b;
  margin: 0;
  padding: 0;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
}

header.hero {
  box-sizing: border-box;
  background-color: #0000;
  background-image: linear-gradient(#001a5b8c 0%, #001a5b 100%), url("march.593d7e2d.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  line-height: 1;
  display: flex;
}

@media (width >= 1024px) {
  header.hero {
    align-items: left;
    padding: 100px;
  }

  header.hero * {
    max-width: 600px;
  }
}

@media (width <= 1024px) {
  header.hero {
    text-align: center;
    align-items: center;
    padding: 70px;
  }
}

@media (width <= 500px) {
  header.hero {
    padding: 20px;
  }
}

h1, h2 {
  text-transform: uppercase;
  margin: 10px;
  line-height: 1;
}

h1 {
  font-size: 7rem;
  font-weight: 900;
}

@media (width <= 1024px) {
  h1 {
    font-size: 65px;
  }
}

@media (width <= 767px) {
  h1 {
    font-size: 50px;
  }
}

h2 {
  font-size: 2rem;
}

@media (width <= 767px) {
  h2 {
    font-size: 1.4rem;
  }
}

p {
  font-size: 26px;
  line-height: 1.5em;
}

@media (width <= 767px) {
  p {
    font-size: 20px;
  }
}

a {
  color: #31c8ff;
  cursor: pointer;
  text-decoration: none;
}

a.dotted {
  color: #e8e6e3;
  border-bottom: 1px dotted #e8e6e3;
}

li {
  padding: .8em .1em;
  font-size: 23px;
}

li p, li summary {
  margin: .1em 0;
  font-size: 21px;
}

main {
  box-sizing: border-box;
  width: 100%;
  padding: 1.5rem;
}

main section {
  max-width: 130ch;
  margin: auto;
}

section {
  color: #fff;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

section h1, section h2 {
  margin: 1em 0 0;
}

section > p {
  margin: 15px 8px;
}

section section {
  margin: 15px;
}

.error {
  color: red;
  animation: 1s fadein;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}

a.calendar-link {
  color: #e8e6e3;
  text-decoration: underline;
}

summary {
  color: #31c8ff;
}

details > summary:after {
  content: "Click to show details";
}

details[open] > summary:after {
  content: "Click to hide details";
}

details p {
  margin: 0 15px;
}

iframe {
  filter: invert(80) hue-rotate(140deg) brightness(1.5);
  width: 100%;
}
/*# sourceMappingURL=index.ef9c53fe.css.map */
